
































import EditPage from "@/components/EditPage";
import { Vue, Component, Prop, Provide } from "vue-property-decorator";
@Component
export default class Buchungen extends EditPage {
  constructor() {
    super();
    this.showLoading = true;
  }
  protected myHeight: number = 900;
  protected day: Date = this.siteData.zeitbereich.von;
  private titel: string = this.siteData.title;
  private name: string = this.siteData.name;
  private daysForward: number = this.siteData.daysForward;
  private daysBack: number = this.siteData.daysBack;
  private goDate: Date = this.siteData.goDate;

  public onRefresh() {
    (this.$refs.timelines as any).onRefresh();
  }
  public mounted() {
    if (this.siteData.aic) {
      this.aic = this.siteData.aic;
    }
    this.mountedBase();
    this.myHeight = window.innerHeight - 100;
  }
}
