


































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import ValueChangeTracker from "./ValueChangeTracker.vue";

@Component
export default class EditCheckBox extends EditComponent {
  private checked: boolean = false;
  public mounted() {
    this.checked = this.getInitValue();
  }

  protected checkedChanged(data: any) {
    this.valueChanged(data.checked); // zum event triggern
  }
}
