import { render, staticRenderFns } from "./MADetailantrag.vue?vue&type=template&id=5eb5befc&scoped=true"
import script from "./MADetailantrag.vue?vue&type=script&lang=ts"
export * from "./MADetailantrag.vue?vue&type=script&lang=ts"
import style0 from "./MADetailantrag.vue?vue&type=style&index=0&id=5eb5befc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eb5befc",
  null
  
)

export default component.exports