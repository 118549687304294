










































































import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { refreshFullPageContent, onShowSite } from "@/NavigationHelper";

@Component
export default class BaseNavigationStamm extends Vue {
  @Prop() protected itemsUebergabe: Tabs[] = [];
  public drawer: boolean = true;
  public mini: boolean = true;
  public saveFrage: boolean = false;
  public saveVisibleStt: boolean = false;
  public step: number = 0;
  constructor() {
    super();
  }
  // public created() {
  //   this.items = this.itemsUebergabe;
  // }
  private group: any = null;
  @Watch("group")
  private groupWatch(val: boolean) {
    if (!val) {
      this.drawer = false;
    }
  }
  @Watch("drawer")
  private drawerWatch(val: boolean, oldval: boolean) {
    if (oldval !== val) {
      this.drawer = val;
    }
  }
  @Watch("mini")
  private miniWatch(val: boolean, oldval: boolean) {
    if (oldval !== val) {
      this.mini = val;
    }
  }
  public onClose() {
    if (this.saveFrage) {
      if (
        confirm(
          "Wollen Sie die Seite wirklich verlassen - es sind nicht alle Daten gespeichert?"
        )
      ) {
        this.saveFrage = false;
        this.$emit("back");
      }
    } else {
      this.$emit("back");
    }
  }
  public onShowSiteLocal(site: string, name: string) {
    if (this.saveFrage || this.saveVisibleStt) {
      if (
        confirm(
          "Wollen Sie die Seite wirklich verlassen - es sind nicht alle Daten gespeichert?"
        )
      ) {
        this.saveFrage = false;
        onShowSite({ site, title: name });
      }
    } else {
      onShowSite({ site, title: name });
    }
  }
  private checkSite(item: any) {
    if (this.saveFrage || this.saveVisibleStt) {
      if (
        confirm(
          "Wollen Sie die Seite wirklich verlassen - es sind nicht alle Daten gespeichert?"
        )
      ) {
        this.saveFrage = false;
        this.step = item.step;
        this.mini = !this.mini;
      }
    } else {
      this.step = item.step;
      this.mini = !this.mini;
    }
  }
}
declare interface Tabs {
  text: string;
  icon: string;
  color?: string;
  step: number;
  component?: string;
  subsites?: any[];
  active?: boolean;
  query?: string;
  activeNumber?: number;
}
