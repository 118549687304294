import { render, staticRenderFns } from "./EditVonBis.vue?vue&type=template&id=4b3a4832&scoped=true"
import script from "./EditVonBis.vue?vue&type=script&lang=ts"
export * from "./EditVonBis.vue?vue&type=script&lang=ts"
import style0 from "./EditVonBis.vue?vue&type=style&index=0&id=4b3a4832&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b3a4832",
  null
  
)

export default component.exports