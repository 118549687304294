


































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { VarUebergabe } from "@/CalcHelperStatic";

@Component
export default class StempelEdit extends EditPage {
  constructor() {
    super();
    this.query = "WEB_BDE_aendern";
    this.bewegungsdaten = true;
    this.showLoading = true;
  }
  private showZeitzone: boolean = false;
  public mounted() {
    const zeitzone = this.checkBegriff("PZE_Web_Zeitzone_Nacherfassen");
    if (zeitzone) {
      this.showZeitzone = true;
    }
    this.mountedBase();
  }
  public save() {
    let varUebergabe: VarUebergabe[] = [];
    if (this.siteData.varUbergabe) {
      varUebergabe = this.siteData.varUbergabe;
    }
    const aicAbfAll = 0;
    const aic: string = "0";
    const me = this;

    this.onSave(aicAbfAll, "", "", aic, true, varUebergabe)?.then(() => {
      this.$nextTick(() => me.$emit("calculated"));
    });
  }
}
