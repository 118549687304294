








































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
// import EditPage from "@/components/EditPage";
import VCardDialog from "@/components/common/VCardDialog.vue";

@Component
export default class WorkflowSalden extends Vue {
  private zeitbereichUrlaub: any;
  private maxWidth = window.innerWidth;
  @Prop({ default: null }) public siteData: any;
  constructor() {
    super();
    if (this.siteData) {
      if (this.siteData.zeitbereich) {
        this.zeitbereichUrlaub = this.siteData.zeitbereich;
        const d = new Date();
        const datumHeute = new Date(d.setHours(0, 0, 0, 0));
        const bisZeitZone = this.zeitbereichUrlaub.bis;
        const bis = new Date(bisZeitZone.setHours(0, 0, 0, 0));
        /* liegt das BIS in der Zukunft - dann darf nur bis HEUTE gerechnet werden */
        if (datumHeute.valueOf() < bis.valueOf()) {
          this.zeitbereichUrlaub.bis = d;
        }
      }
    }
  }
}
