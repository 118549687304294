





























import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import BaseSyncSchedule from "../baseSyncSchedule.vue";

@Component
export default class TooltipTemplate extends Vue {
  @Prop() public row!: any;
  @Prop() public schedule!: BaseSyncSchedule;

  public rowdata: any;
  private start: string = "";
  private end: string = "";
  private ganzTag!: string;

  // public mounted() {
  //   const me = this;
  //   try {
  //     if (
  //       // nach dem Komilieren geht diese bedingung nicht..30.11.2020
  //       this.$options &&
  //       this.$options.components &&
  //       this.$options.components.TooltipTemplate
  //     ) {
  //       const x: any = this.$options.components.TooltipTemplate;
  //       this.rowdata = this.$data.data as any;
  //       const isAllDay = this.rowdata.IsAllDay;
  //       let bis = new Date(this.rowdata.EndTime);
  //       const von = new Date(this.rowdata.StartTime);
  //       let format = "dd.MM.yyyy";
  //       if (isAllDay) {
  //         bis = new Date(this.rowdata.EndTime).addDays(-1);
  //         this.ganzTag = "ja";
  //       } else {
  //         this.ganzTag = "nein";
  //         format = "HH:mm";
  //       }
  //       const mask = CalenderHelper.getMask(format);
  //       this.end = CalenderHelper.valueToFormatStr(bis, format);
  //       this.start = CalenderHelper.valueToFormatStr(von, format);
  //     }
  //   } catch (error) {
  //     console.error("Fehler im tooltip: " + error);
  //   }
  // }
}
