















































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getKennungValueDisplay, getKennungTitle } from "@/DataHelper";
import { Globals } from "@/Globals";

@Component
export default class DialogAntrag extends EditPage {
  @Prop() public zeitbereichUebergabe!: APIZeitbereich | null;
  // protected zeitbereichUebergabe: any;
  // @Prop({ default: null }) protected zeitbereich!: APIZeitbereich | null;
  constructor() {
    super();
    this.query = "WebWF_Freigabe_Antrag";
    this.showLoading = true;
  }
  private showUestAuszahlen: boolean = false;
  private showauszahlungUest: boolean = false;
  private showZulage1: string = "";
  private showZulage2: string = "";
  private showZulage3: string = "";
  protected saveVisible: boolean = false;
  public created() {
    this.zeitbereich = this.zeitbereichUebergabe;
  }
  public bewDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.onDelete(this.data.aic_Bew_pool, "");
      this.$emit("back");
    }
  }
  public save() {
    // this.onSave(this.data, "");
    // const aicAbfAllBez = this.$globalsKennung(Globals.Begriff, this.query);
    const aicAbfAll = 0;
    let aic: any;
    const me = this;
    if (this.aic) {
      aic = this.aic;
    }
    this.onSave(aicAbfAll, "", "", aic)?.then(() => {
      this.$nextTick(() => me.$emit("save"));
    });
  }
  protected initialize() {
    const uestAuszahlen = getKennungTitle("ZE_UEST_NICHT_AUSZAH", this.columns);
    if (uestAuszahlen) {
      this.showUestAuszahlen = true;
    }
    const auszahlungUest = getKennungTitle("ZE_AUSZAHLUNG_UEST", this.columns);
    if (auszahlungUest) {
      this.showauszahlungUest = true;
    }
    this.showZulage1 = getKennungTitle("Zulage_1", this.columns);
    this.showZulage2 = getKennungTitle("Zulage_2", this.columns);
    this.showZulage3 = getKennungTitle("Zulage_3", this.columns);
  }
  private checkChanged(val: any) {
    this.saveVisible = true;
  }
}
