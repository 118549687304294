import "./chartistLegend.css";
import { PieChart, BarChart, alphaNumerate } from "chartist";

export interface LegendOptions {
    className?: string;
    classNames?: boolean;
    removeAll?: boolean;
    legendNames: string[];
    clickable?: boolean;
    onClick?: () => {};
    position?: 'top' | 'bottom';
}
function extend(target: any, source: any) {
    if (source) {
        for (const prop in source) {
            if (source.hasOwnProperty(prop)) {
                target[prop] = source[prop];
            }
        }
    }
    return target;
}

export function chartistLegend(options: LegendOptions) {
    /**
     * This Chartist plugin creates a legend to show next to the chart.
     *
     */
    'use strict';

    const defaultOptions = {
        className: '',
        classNames: false,
        removeAll: false,
        legendNames: false,
        clickable: true,
        onClick: null,
        position: 'top'
    };


    options = extend(defaultOptions, options);

    let cachedDOMPosition = options.position;
    // Catch invalid options
    if (options && options.position) {
        if (!(options.position === 'top' || options.position === 'bottom')) {
            throw Error('The position you entered is not a valid position');
        }
        if (options.position) {
            // Detatch DOM element from options object, because Chartist.extend
            // currently chokes on circular references present in HTMLElements
            cachedDOMPosition = options.position;
            delete options.position;
        }
    }

    if (cachedDOMPosition) {
        // Reattatch the DOM Element position if it was removed before
        options.position = cachedDOMPosition;
    }

    return function legend(chart: any, opt?: any) {

        const ooptions = extend(defaultOptions, options ?? {});

        function removeLegendElement() {
            const llegendElement = chart.container.querySelector('.ct-legend');
            if (llegendElement) {
                llegendElement.parentNode.removeChild(llegendElement);
            }
        }

        // Set a unique className for each series so that when a series is removed,
        // the other series still have the same color.
        function setSeriesClassNames() {
            chart.data.series = chart.data.series.map((series: any, seriesIndex: number) => {
                if (typeof series !== 'object') {
                    series = {
                        value: series
                    };
                }
                series.className = series.className || chart.options.classNames.series + '-'
                    + alphaNumerate(seriesIndex);
                return series;
            });
        }

        function createLegendElement() {
            const llegendElement = document.createElement('ul');
            llegendElement.className = 'ct-legend';
            if (chart instanceof PieChart) {
                llegendElement.classList.add('ct-legend-inside');
            }
            if (typeof ooptions.className === 'string' && ooptions.className.length > 0) {
                llegendElement.classList.add(ooptions.className);
            }
            if (chart.options.width) {
                llegendElement.style.cssText = 'width: ' + chart.options.width + 'px;margin: 0 auto;';
            }
            return llegendElement;
        }

        // Get the right array to use for generating the legend.
        function getLegendNames(uuseLabels: boolean) {
            return ooptions.legendNames || (uuseLabels ? chart.data.labels : chart.data.series);
        }

        // Initialize the array that associates series with legends.
        // -1 indicates that there is no legend associated with it.
        function initSeriesMetadata(uuseLabels: boolean) {
            const sseriesMetadata = new Array(chart.data.series.length);
            for (let i = 0; i < chart.data.series.length; i++) {
                sseriesMetadata[i] = {
                    data: chart.data.series[i],
                    label: uuseLabels ? chart.data.labels[i] : null,
                    legend: -1
                };
            }
            return sseriesMetadata;
        }

        function createNameElement(i: string, legendText: string, cclassNamesViable: boolean, color: string) {
            const li = document.createElement('li');
            li.classList.add('ct-legend-item');
            li.classList.add('ct-legend-' + String.fromCharCode(97 + parseInt(i, 10)));
            // Append specific class to a legend element, if viable classes are given
            if (cclassNamesViable) {
                li.classList.add(ooptions.classNames[i]);
            }

            li.setAttribute('data-legend', i);
            li.textContent = legendText;
            return li;
        }

        // Append the legend element to the DOM
        function appendLegendToDOM(llegendElement: any) {
            switch (ooptions.position) {
                case 'top':
                    chart.container.insertBefore(llegendElement, chart.container.childNodes[0]);
                    break;
                case 'bottom':
                    chart.container.insertBefore(llegendElement, null);
                    break;
                default:
                    chart.container.insertBefore(llegendElement, null);
                    break;

            }
        }

        function addClickHandler(llegendElement: any, llegends: any, sseriesMetadata: any, uuseLabels: any) {
            llegendElement.addEventListener('click', (e: any) => {
                const li = e.target;
                if (li.parentNode !== llegendElement || !li.hasAttribute('data-legend')) {
                    return;
                }
                e.preventDefault();

                const legendIndex = parseInt(li.getAttribute('data-legend'), 10);
                const llegend = llegends[legendIndex];
                if (!llegend.active) {
                    llegend.active = true;
                    li.classList.remove('ct-legend-remove');
                } else {
                    llegend.active = false;
                    li.classList.add('ct-legend-remove');

                    const activeCount = llegends.filter((l: any) => l.active).length;
                    if (!ooptions.removeAll && activeCount === 0) {
                        // If we can't disable all series at the same time, let's
                        // reenable all of them:
                        for (let i = 0; i < llegends.length; i++) {
                            llegends[i].active = true;
                            llegendElement.childNodes[i].classList.remove('ct-legend-remove');
                        }
                    }
                }

                const newSeries = [];
                const newLabels = [];

                for (let i = 0; i < sseriesMetadata.length; i++) {
                    if (sseriesMetadata[i].legend !== -1 && llegends[sseriesMetadata[i].legend].active) {
                        newSeries.push(sseriesMetadata[i].data);
                        newLabels.push(sseriesMetadata[i].label);
                    }
                }

                chart.data.series = newSeries;
                if (uuseLabels) {
                    chart.data.labels = newLabels;
                }

                chart.update();

                if (ooptions.onClick) {
                    ooptions.onClick(chart, e);
                }
            });
        }

        removeLegendElement();

        const legendElement = createLegendElement();

        const chartAny: any = chart;

        const useLabels = chart instanceof PieChart && chartAny.data.labels && chartAny.data.labels.length;
        const legendNames = getLegendNames(useLabels);
        const seriesMetadata = initSeriesMetadata(useLabels);
        const legends: any[] = [];

        // Check if given class names are viable to append to legends
        const classNamesViable = Array.isArray(ooptions.classNames)
            && ooptions.classNames.length === legendNames.length;

        // Loop through all legends to set each name in a list item.
        legendNames.forEach((llegend: any, i: any) => {
            const legendText = llegend.name || llegend;
            const legendSeries = llegend.series || [i];
            const series = chart.data.series[i];
            let color = "";
            if (series && series.length > 0) {
                const firstdatapoint = series[0];
                if (typeof firstdatapoint === "object") {
                    color = firstdatapoint?.meta?.color;
                }
            }
            const li = createNameElement(i, legendText, classNamesViable, color);
            legendElement.appendChild(li);

            legendSeries.forEach((seriesIndex: number) => {
                seriesMetadata[seriesIndex].legend = i;
            });

            legends.push({
                text: legendText,
                series: legendSeries,
                active: true
            });
        });

        chart.on('created', (data: any) => {
            appendLegendToDOM(legendElement);
        });

        if (ooptions.clickable) {
            setSeriesClassNames();
            addClickHandler(legendElement, legends, seriesMetadata, useLabels);
        }
    };
}

