import { render, staticRenderFns } from "./Buchungen.vue?vue&type=template&id=307f5747&scoped=true"
import script from "./Buchungen.vue?vue&type=script&lang=ts"
export * from "./Buchungen.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "307f5747",
  null
  
)

export default component.exports