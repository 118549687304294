



















import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";

@Component
export default class SonstigeBelege extends EditPage {
  public showTitle: boolean = true;
  private title: string = "";
  public mounted() {
    if (this.siteData?.site?.compTitle) {
      this.showTitle = true;
      this.title = this.siteData.site.compTitle;
    } else if (this.siteData?.titel) {
      this.showTitle = true;
      this.title = this.siteData.titel;
    }
  }
  private onReloadData() {
    this.siteData.onReloadData = true;
  }
}
