









































import { Vue, Component, Prop } from "vue-property-decorator";
import { showDialog } from "@/UIHelper";
// import BaseEditor from "./BaseEditor.vue";
import { onShowSite, refreshFullPageContent } from "@/NavigationHelper";
import Constants from "@/Constants";

@Component
export default class SubMasken extends Vue {
  protected editZeitbereich: APIZeitbereich | null = null;
  @Prop() protected buttonItems!: ButtonItems[];
  @Prop() public aic!: number;
  @Prop() public name!: string;
  @Prop({ default: false }) private pers!: boolean;
  constructor() {
    super();
  }
  private showDialog: boolean = false;

  private openSite(item: any) {
    const me = this;
    const stammtyp = this.buttonItems[0].stammtyp;

    // if (this.$api.user.stt !== stammtyp) {
    //   this.$api.setSyncStamm(stammtyp, this.aic);
    // }
    const aic = this.aic;
    onShowSite({
      title: item.compTitle,
      site: item.compName,
      data: {
        zeitbereich: this.$api.zeitbereich,
        item,
        aic,
        toggleUebergabe: item.toggle,
        showBack: true,
        name: me.name,
        titel: me.name,
        gotoToday: false,
        site: item,
      },
      // titleColor: "dvhBackground",
      // titleClass: "white--text",
      callOnLeave: () => {
        setTimeout(() => refreshFullPageContent());
        // return true;
      },
    });
  }
  private openDialog(item: any) {
    const aic = this.aic;
    let site = "BaseDialog";
    if (item.grid) {
      site = "BaseVGrid";
    }
    showDialog({
      title: item.compTitle,
      width: 500,
      site,
      fullscreen: item.fullscreen,
      data: {
        zeitbereich: this.$api.zeitbereich,
        query: item.query,
        aic,
        pers: this.pers,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
      },
    });
  }
}
declare interface ButtonItems {
  width?: number;
  height?: number;
  fullscreen: boolean;
  showCustomEditor: boolean;
  query: string;
  compName?: string;
  compNameFrm?: string;
  compTitle?: string;
  icon: string;
  text: number;
  kz?: string;
  aicEig?: number;
  color: string;
  toggle: string;
  tooltip: string;
  stammtyp: number;
}
