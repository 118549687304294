<template>
  <KanbanBoard></KanbanBoard>
</template>
<script>
export default {
  data() {
    return {
      aic: 0,
    };
  },
};
</script>

<style scoped></style>
