var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-0",attrs:{"loading":_vm.showLoading}},[_c('v-card-title',{staticClass:"dvhBackground white--text headline"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),(_vm.hsItems)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"20px, padding: 0px"},attrs:{"text":""}},on),[_c('v-icon',[_vm._v("view_list")])],1)]}}],null,false,2751773338)},[_c('v-list',_vm._l((_vm.hsItems),function(hsItem,ux){return _c('v-list-item',{key:'ui' + ux,on:{"click":function($event){return _vm.onHsItemClick(
              hsItem.Aic,
              hsItem.Bezeichnung,
              hsItem.prog,
              hsItem.Austritt,
              hsItem.offen
            )}}},[_c('v-list-item-title',[_vm._v(_vm._s(hsItem.Bezeichnung))])],1)}),1)],1):_vm._e()],1),_c('v-card-text',[(_vm.hsItems && _vm.initalized)?_c('TreeGridView',{attrs:{"query":"Web_MA_TreeView_Vorgesetzter","hsAic":_vm.hsAic,"hsAbfragen":_vm.hsSubAbfragen,"hsFormulare":_vm.hsStandardFormular,"printItems":_vm.druckItems,"showSelectAustritt":_vm.showSelectAustritt,"openAll":_vm.openAll},on:{"reload":_vm.reloadData}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }